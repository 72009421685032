<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" label="讲师名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pic" label="讲师头像" show-overflow-tooltip>
        <template #default="s">
          <img :src="s.row.pic | tomedia" style="height: 3rem;width: 3rem;border: .5rem" alt="">
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template #default="s">
          <el-button @click="$router.push({
          name:'shopPluginKnowledgeTeacherAward',
          query:{
            id:s.row.id
          }
          })">佣金记录</el-button>
          <el-button @click="$router.push({
          name:'shopPluginKnowledgeTeacherEdit',
          query:{
            id:s.row.id
          }
          })" type="primary">编辑</el-button>
          <el-button @click="remove(s.row)" type="danger">删除</el-button>
        </template>
        <template #header>
          <el-button @click="$router.push({
          name:'shopPluginKnowledgeTeacherEdit',
          })">添加讲师</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "cate",
  data(){
    return{
      list:[],
      page:1,
      total:0,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.knowledge.teacher({
        page:this.page
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    remove({id}){
      this.$u.api.shop.plugin.knowledge.teacherDel({id}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
  },
}
</script>

<style scoped>

</style>